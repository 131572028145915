/* eslint-disable no-empty-pattern */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */

import api from '@services/api';

export type FetchByLocationProps = {
  label: string;
  options: Array<{
    id: string;
    label: string;
  }>;
};

async function fetchLocationByTerm(term): Promise<FetchByLocationProps[]> {
  const empresaId = process.env.NEXT_PUBLIC_EMPRESA_ID;
  const queryParams = new URLSearchParams();

  queryParams.append('term', term);
  queryParams.append('empresaId', empresaId);

  const stringified = queryParams.toString();

  const responseData: FetchByLocationProps[] = await api
    .get(`site/localidades?${stringified}`)
    .then((res: any) => {
      return res.data || [];
    });

  return responseData || [];
}

export default fetchLocationByTerm;
