import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import Button from '@components/ButtonDynamic';
import { isDesktop } from 'react-device-detect';
import { Tooltip } from 'antd';

type PersonalBannerProps = {
  title?: string;
  subTitle?: string;
  textButton?: string;
  hasButton?: boolean;
};

const PersonalBanner = ({
  title,
  hasButton = false,
  textButton = '',
}: PersonalBannerProps): JSX.Element => {
  return (
    <>
      <div
        className="personal-banner"
        id="personal"
        data-aos="fade-up"
        data-aos-delay="300">
        <Container>
          <div className="personal-banner-container">
            <Row className="no-gutters h-100">
              <Col xs="12" md="6"></Col>
              <Col xs="12" md="6">
                <div className="d-flex flex-column h-75 pt-4">
                  <div className="h-100 d-flex flex-column justify-content-center pr-4">
                    <div className="py-4 mb-4 pr-4 w-75">
                      <h3>{title}</h3>
                    </div>
                    {hasButton ? (
                      <div className="py-4 my-4 pr-4">
                        <Row
                          className={
                            isDesktop
                              ? 'no-gutters'
                              : 'justify-content-md-center no-gutters'
                          }>
                          <Col xs="1" md="12"></Col>
                          <Col xs="10" md="6">
                            <Tooltip title="Funcionalidade em construção...">
                              <a
                                // href="https://wa.me/556291391650"
                                target="_blank">
                                <Button
                                  size="big"
                                  fluid
                                  color="grey"
                                  onClick={() => { }}>
                                  {textButton}
                                </Button>
                              </a>
                            </Tooltip>
                          </Col>
                          <Col xs="1" md="12"></Col>
                        </Row>
                      </div>
                    ) : null}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default PersonalBanner;
