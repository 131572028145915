/*eslint-disable*/
import React from 'react';
import {Button} from 'semantic-ui-react';

const ButtonDynamic = (props) => {
  const {children, isloading, loadtext} = props;

  if (isloading === 'true') {
    return (
      <Button {...props} disabled>
        <div className="loading"></div>
        {loadtext}
      </Button>
    );
  }

  return <Button {...props}>{children}</Button>;
};

export default ButtonDynamic;
