import React, { useState, useEffect } from 'react';
import { parseCookies } from 'nookies';
import { useRouter } from 'next/router';
import { isDesktop } from 'react-device-detect';
import { Container, Row, Col } from './styles';

import NewSearchBar from '@components/NewSearchBar/NewSearchBar';

const Hero = ({
  setIsLoading,
  setLoadingText,
  cidadeId,
  cityGroup,
  finalidades,
}) => {
  const [visibleForm, setVisibleForm] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const COOKIE_NAME = 'mb_state';
  const router = useRouter();
  const cookies = parseCookies();
  const cookieCidadeId = cookies[COOKIE_NAME];
  const [cityValue, setCityValue] = useState(
    cidadeId || Number(cookieCidadeId) || undefined,
  );

  const handleSubmitFilter = async (queryParams) => {
    try {
      setIsFetching(true);
      router.push(`/busca-rapida?${queryParams}`);
    } catch (error) {
      console.error(error);
      setIsFetching(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (cidadeId || cookieCidadeId) {
      setCityValue(cidadeId || Number(cookieCidadeId));
      setVisibleForm(true);
    }
  }, [cidadeId, cookieCidadeId]);

  return (
    <>
      <section id="hero" className="hero-leads">
        <div className="d-flex justify-content-center flex-row">
          <Container>
            <Row className="d-flex items-center align-items-center">
              <Col xs="12" md="12">
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                  }}
                  className="text-left text-white p-4">
                  <span
                    className="hero-title"
                    style={{ fontSize: '36px', fontWeight: 'bolder' }}>
                    A Imobiliária que mais cresce no Brasil
                  </span>
                </div>
              </Col>
              <Col xs="12" md="12">
                <div className="search-home">
                  <NewSearchBar
                    onSubmit={handleSubmitFilter}
                    isLoading={isFetching}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </>
  );
};

export default Hero;
