import api from './api';

async function fetchPurposes(): Promise<any> {
  const empresaId = process.env.NEXT_PUBLIC_EMPRESA_ID;
  const queryParams = new URLSearchParams();

  queryParams.append('empresaId', empresaId);
  const stringifiedParams = queryParams.toString();

  const responseData = await api
    .get(`site/finalidades?${stringifiedParams}`)
    .then((res: any) => {
      return res?.data || [];
    });

  let sordedList = []
  for (let index = 0; index < responseData.length; index++) {
    const element = responseData[index];
    if (element.value === 'Residencial')
      sordedList.push(element);
  }
  for (let index = 0; index < responseData.length; index++) {
    const element = responseData[index];
    if (element.value === 'Comercial')
      sordedList.push(element);
  }
  for (let index = 0; index < responseData.length; index++) {
    const element = responseData[index];
    if (element.value !== 'Residencial' && element.value !== 'Comercial')
      sordedList.push(element);
  }
  return sordedList;
}

export default fetchPurposes;
