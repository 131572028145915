import React, {useState, useEffect} from 'react';
import {NextSeo} from 'next-seo';
import {parseCookies, setCookie} from 'nookies';
import {Row, Col} from 'reactstrap';
import {Dimmer, Loader, Modal, Icon} from 'semantic-ui-react';
import {Form, Select, Tooltip} from 'antd';

import fetchImMobile from '@services/fetch-immobile';
import fetchCitiesGroup from '@services/fetch-cities-group';
import fetchImMobileGoal from '@services/fetch-immobile-goal';
import Layout from '@components/Layout';
import Hero from '@components/Hero';
import SpotLight from '@components/SpotLight';
import CallToAction from '@components/CallToAction';
import CalculatorBanner from '@components/CalculatorBanner';
import Service from '@components/Service';
import PersonalBanner from '@components/PersonalBanner';
import Whatsapp from '@components/Whats';
import Button from '@components/ButtonDynamic';
import About from '@components/About';

import FeaturedProperties from '@components/FeaturedProperties';
import useContact from '@components/Contact/hooks/use-contact';

export async function getStaticProps() {
  const empreendimentos = await fetchImMobile({
    props: {
      empreendimento: true,
    },
  });
  const popularData = await fetchImMobile({
    props: {
      destaque: true,
    },
  });

  const newerData = await fetchImMobile({
    props: {
      novidade: true,
    },
  });

  const cityGroup = await fetchCitiesGroup();
  const imMobileGoalData = await fetchImMobileGoal();

  let props = {};

  if (popularData) {
    props = {
      ...props,
      popular: popularData,
    };
  }

  if (newerData) {
    props = {
      ...props,
      novidade: newerData,
    };
  }

  if (cityGroup) {
    props = {
      ...props,
      cidadesPorGrupo: cityGroup,
    };
  }
  if (empreendimentos) {
    props = {
      ...props,
      empreendimentos: empreendimentos,
    };
  }
  if (imMobileGoalData) {
    props = {
      ...props,
      imMobileGoal: imMobileGoalData,
    };
  }

  return {
    props,
  };
}

type HomePageProps = {
  popular?: any;
  cidadesPorGrupo: any;
  empreendimentos: any;
  imMobileGoal: any;
  novidade?: any;
};

const Home = ({
  cidadesPorGrupo,
  popular,
  novidade,
  empreendimentos,
  imMobileGoal,
}: HomePageProps): JSX.Element => {
  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState('Buscando imóveis...');
  const [showDrawer, setShowDrawer] = useState(false);
  const [acceptedCookies, setAcceptedCookies] = useState(false);
  const [cityCookies, setCityCookies] = useState(null);
  const {companyList} = useContact();
  const phoneNumber = `55${companyList[0]?.phone.replace(/\D/g, '')}` || null;
  const COOKIE_NAME = 'mb_state';
  const isMbMatriz = process.env.NEXT_PUBLIC_EMPRESA_ID === '025c1450-3ab4-4e5d-8e3c-d69917235546';

  useEffect(() => {
    window.scrollTo(0, 0);
    const cookies = parseCookies();
    if (!cookies[COOKIE_NAME]) {
      setAcceptedCookies(false);
      setShowDrawer(true);
    }
  }, []);

  const hasSpotLight = popular?.length >= 1 || empreendimentos?.length >= 1;

  return (
    <>
      <NextSeo
        title="My Broker | Soluções Imobiliárias"
        description="My Broker Soluções Imobiliárias"
        canonical="https://www.mybrokerimoveis.com.br"
        twitter={{
          site: '@mybroker',
          cardType: 'summary',
        }}
        openGraph={{
          url: 'https://www.mybrokerimoveis.com.br',
          type: 'website',
          title: 'My Broker | Soluções Imobiliárias',
          description: 'My Broker Soluções Imobiliárias',
          images: [
            {
              url: 'https://locare.s3.sa-east-1.amazonaws.com/sites/my-broker/locare_seo.png?size=small',
              width: 800,
              height: 600,
              alt: 'My Broker | Soluções Imobiliárias',
              type: 'image/png',
            },
          ],
          site_name: 'My Broker | Soluções Imobiliárias',
        }}
      />
      <Hero
        finalidades={imMobileGoal}
        cityGroup={cidadesPorGrupo}
        cidadeId={Number(cityCookies)}
        setIsLoading={(v) => setIsLoading(v)}
        setLoadingText={(v) => setLoadingText(v)}
      />
      {popular?.data && popular?.data.length > 0 && (
        <FeaturedProperties title="Imóveis em Destaque" data={popular?.data} />
      )}
      {hasSpotLight ? (
        <section className="spotlight">
          <SpotLight dataImmobile={popular} title="Imóveis em destaque" />
          <SpotLight
            dataImmobile={empreendimentos}
            title="Imóveis lançamentos"
            hasButton
            hasButtonLink="/busca-rapida"
          />
        </section>
      ) : null}
      <About />
      <CallToAction
        title="Proprietário"
        subTitle="Quer anunciar seu imóvel ?"
        hasButton
        textButton="Converse com um dos nossos especialistas"
      />

      {novidade?.data && novidade?.data.length > 0 && (
        <FeaturedProperties title="Novidades" data={novidade?.data} />
      )}

      <CalculatorBanner
        title="Calculadora e simulador de financiamento imobiliário"
        subTitle="Use nossa calculadora para descobrir seu potencial de compra e escolha 
        como usá-lo da forma mais inteligente possível."
        hasButton
        textButton="Simule aqui o seu financiamento"
      />
      <section className="spotlight">
        <Service />
        <PersonalBanner
          title="Venha fazer parte da imobiliária que mais cresce no Brasil!"
          hasButton
          textButton="Seja um Personal Broker"
        />
      </section>
      {phoneNumber && !isMbMatriz && <Whatsapp phoneNumber={phoneNumber} />}
      {isLoading ? (
        <Dimmer active>
          <Loader size="massive">{loadingText}</Loader>
        </Dimmer>
      ) : null}
      {/* {!acceptedCookies ? (
        <Modal
          basic
          onClose={(v) => acceptingCookie(v)}
          onOpen={() => setShowDrawer(true)}
          open={showDrawer}
          size="small">
          <Modal.Content>
            <Form form={form} scrollToFirstError>
              <Row className="justify-content-md-center no-gutters">
                <Col xs="12">
                  <div className="text-center">
                    <h1 className="text-white my-4">Escolha sua Cidade</h1>
                  </div>
                </Col>
              </Row>
              <Row className="justify-content-md-center no-gutters">
                <Col xs="12" md="10">
                  <Form.Item name="cidadeId">
                    <Select
                      size="large"
                      className="place-form"
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option: any) =>
                        option.children
                          .normalize('NFD')
                          .replace(/[\u0300-\u036f]/g, '')
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      placeholder=" Cidade"
                      onChange={hasOnChange}
                      allowClear>
                      {cidadesPorGrupo.map((item) => (
                        <Option key={item.id} value={item.id}>
                          {item.nome}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <Row className="justify-content-md-center no-gutters">
              <Col xs="12" md="5">
                <Button
                  icon
                  labelPosition="left"
                  fluid
                  color="blue"
                  onClick={() =>
                    setPageCookie({name: COOKIE_NAME, value: cityCookies})
                  }>
                  <Icon name="send" />
                  Selecionar
                </Button>
              </Col>
            </Row>
          </Modal.Content>
        </Modal>
      ) : null} */}
    </>
  );
};

export default Home;

Home.Layout = Layout;
