import React, { useEffect, useMemo, useRef, useState } from 'react';
import debounce from 'lodash/debounce';

import { Spin } from 'antd';
import { TagSelect } from '../styles';

export default function DebounceSelect({
  fetchOptions,
  debounceTimeout = 800,
  getLastOptions = null,
  ...props
}) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const fetchRef = useRef(0);

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          return;
        }
        setOptions(newOptions);
        setFetching(false);
      });
    };
    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  useEffect(() => {
    if (getLastOptions && options?.length > 0) {
      getLastOptions(options);
    }
  }, [options]);

  return (
    <TagSelect
      labelInValue
      filterOption={(input, option) => option?.value?.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
        .indexOf(input?.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) >= 0
      }
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
      options={options}
    />
  );
}
