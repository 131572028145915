import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import Button from '@components/ButtonDynamic';
import { Tooltip } from 'antd';

type CalculatorBannerProps = {
  title?: string;
  subTitle?: string;
  textButton?: string;
  hasButton?: boolean;
};

const CalculatorBanner = ({
  title,
  subTitle,
  hasButton = false,
  textButton = '',
}: CalculatorBannerProps): JSX.Element => {
  return (
    <>
      <div className="calculator-banner" data-aos="fade-up">
        <Container>
          <div className="calculator-banner-container">
            <Row className="justify-content-md-center no-gutters">
              <Col xs="12" md="7">
                <h3>{title}</h3>
              </Col>
              <Col xs="12" md="5"></Col>
            </Row>
            <Row className="justify-content-md-center no-gutters">
              <Col xs="12" md="7">
                <p>{subTitle}</p>
              </Col>
              <Col xs="12" md="5"></Col>
            </Row>
            {hasButton ? (
              <Row className="my-4 no-gutters">
                <Col xs="12" md="4">
                  <Tooltip title="Funcionalidade em construção...">
                    <Button size="big" fluid onClick={() => { }} color="grey">
                      {textButton}
                    </Button>
                  </Tooltip>
                </Col>
              </Row>
            ) : null}
          </div>
        </Container>
      </div>
    </>
  );
};

export default CalculatorBanner;
