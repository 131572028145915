import React from 'react';
import {Row, Col} from 'reactstrap';

const About = () => {
  return (
    <section id="about" className="about my-4">
      <div className="container" data-aos="fade-up">
        <div className="row gx-0">
          <div
            className="col-lg-8 d-flex flex-column justify-content-center"
            data-aos="fade-up"
            data-aos-delay="200">
            <div className="content">
              <h3>A My Broker</h3>
              <Row data-aos="fade-up" data-aos-delay="350">
                <Col xs="12" md="6">
                  <h2>Atendimento personalizado</h2>
                  <p>
                    Conhecer as necessidades de cada cliente e as soluções que
                    mercado oferece, a fim de otimizar o tempo de procurar a
                    solução ideal, seja em investimento ou moradia.
                  </p>
                </Col>
                <Col xs="12" md="6">
                  <h2>Eficácia</h2>
                  <p>
                    Ir além do esperado, convergindo em um só lugar, venda,
                    financiamento, aluguel, personalização, serviços de seguro e
                    revenda. Além de um serviço personalizado em investimento
                    imobiliário.
                  </p>
                </Col>
              </Row>
              <Row data-aos="fade-up" data-aos-delay="450" className="mt-3">
                <Col xs="12" md="6">
                  <h2>Inovação</h2>
                  <p>
                    Quebrar paradigmas tanto no desenvolvimento quanto na gestão
                    da My Broker Imobiliária, adequando-se e criando sempre
                    novas tendências de mercado.
                  </p>
                </Col>
                <Col xs="12" md="6">
                  <h2>Integridade</h2>
                  <p>
                    Uma conduta reta, justa e transparente, permeando nossas
                    atitudes, criando relacionamentos sólidos e duradouros.
                  </p>
                </Col>
              </Row>
            </div>
          </div>

          <div
            className="col-lg-4 d-flex align-items-center"
            data-aos="zoom-out"
            data-aos-delay="200">
            <div className="mx-2" style={{padding: '60px'}}>
              <img
                src="https://locare.s3.sa-east-1.amazonaws.com/sites/my-broker/simbolo-mybroker.jpeg?size=small"
                className="img-fluid"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
