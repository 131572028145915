import styled from 'styled-components';

import {Container as RSContainer, Row as RSRow, Col as RSCol} from 'reactstrap';

export const Container = styled(RSContainer)`
  padding-block: 150px;

  @media (max-width: 988px) {
    padding-block: 65px;

    .hero-title {
      text-align: center !important;
      font-size: 25px !important;
    }
  } ;
`;
export const Row = styled(RSRow)``;
export const Col = styled(RSCol)``;
