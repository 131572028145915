import React from 'react';
import useContact from './hooks/use-contact';

const Contact = () => {
  const {companyList} = useContact();

  const renderCompanyList = companyList?.map((item, index) => (
    <div
      key={index}
      className="col-md-3"
      data-aos="zoom-out"
      data-aos-delay="200">
      <div className="feature-box d-flex align-items-center">
        <i className="ri-map-pin-line"></i>
        <p>
          <span>{item.name}</span>
          <br />
          <span> {item.address}</span>
          <br />
          <span>{item.phone}</span>
        </p>
      </div>
    </div>
  ));

  return (
    <section id="contact" className="features my-4">
      <div className="container">
        <div className="row feature-icons" data-aos="fade-up">
          <h3>Contatos</h3>
        </div>

        <div className="row  gy-4" style={{justifyContent: 'center'}}>{renderCompanyList}</div>
      </div>
    </section>
  );
};

export default Contact;
