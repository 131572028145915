import React from 'react';
import { Row, Col, Container } from 'reactstrap';

import Button from '@components/ButtonDynamic';
import { Tooltip } from 'antd';

type CallToActionProps = {
  title?: string;
  subTitle?: string;
  textButton?: string;
  hasButton?: boolean;
};

const CallToAction = ({
  title,
  subTitle,
  hasButton = false,
  textButton = '',
}: CallToActionProps): JSX.Element => {
  return (
    <>
      <div className="cta">
        <Container>
          <Row className="my-4 justify-content-md-center no-gutters">
            <Col xs="12">
              <p className="text-center">{title}</p>
            </Col>
          </Row>
          <Row className="my-4 justify-content-md-center no-gutters">
            <Col xs="12">
              <h3 className="text-center">{subTitle}</h3>
            </Col>
          </Row>
          {hasButton ? (
            <Row className="my-4 justify-content-md-center no-gutters">
              <Col xs="12" md="4">
                <a
                  // href="https://wa.me/556291391650" 
                  target="_blank">
                  <Tooltip title="Funcionalidade em construção...">
                    <Button size="big" fluid color="orange" onClick={() => { }}>
                      {textButton}
                    </Button>
                  </Tooltip>
                </a>
              </Col>
            </Row>
          ) : null}
        </Container>
      </div>
    </>
  );
};

export default CallToAction;
