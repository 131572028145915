import React, {useState} from 'react';

import 'swiper/css';
import 'swiper/css/pagination';

import {Swiper, SwiperSlide} from 'swiper/react';

import {
  Autoplay,
  Controller,
  EffectFade,
  Navigation,
  Pagination,
} from 'swiper/modules';
import CardImmobile from '@components/CardImmobile';
import {useMediaQuery} from 'usehooks-ts';

const immobiles = [
  {
    codImovel: '001',
    operacaoImobiliaria: 1,
    tituloAmigavel: 'Imóvel 1',
    valorAluguel: 1500,
    valorVenda: null,
    imagens: [
      {
        destaque: true,
        arquivo:
          'https://d3873uwf1n1xlo.cloudfront.net/CRM/MY%20BROKER%20PRIME/DGVbSltN.png',
      },
    ],
    logradouro: {
      nome: 'Rua das Flores',
      bairro: {
        nome: 'Jardim Amarelo',
        cidade: {
          nome: 'Cidade Feliz',
          estadoId: 'CF',
        },
      },
    },
    tipoImovel: {
      id: 'c11f31bd-51fc-4dfb-b4d0-5c5d504bc45a',
      finalidade: {nome: 'Residencial'},
      descricao: 'Casa',
    },
    caracteristica: {
      areaConstruida: 100,
      quantidadeQuartos: 3,
      quantidadeSuites: 1,
      vagasGaragem: 1,
      vagasGaragemDescobertas: 1,
    },
  },
  // Copie o objeto acima e ajuste os valores para criar mais 9
  // ou utilize o snippet abaixo para gerar automaticamente 10 itens similares:
  ...Array.from({length: 9}, (_, i) => ({
    codImovel: ('00' + (i + 2)).slice(-3), // gera códigos de "002" a "010"
    operacaoImobiliaria: (i % 3) + 1, // alterna entre 1, 2 e 3
    tituloAmigavel: `Imóvel ${i + 2}`,
    valorAluguel: 1500 + i * 100, // varia o valor de aluguel
    valorVenda: 200000 + i * 10000, // varia o valor de venda
    imagens: [
      {
        destaque: true,
        arquivo: `https://d3873uwf1n1xlo.cloudfront.net/CRM/MY%20BROKER%20PRIME/DGVbSltN.png`,
      },
    ],
    logradouro: {
      nome: `Rua ${i + 2}`,
      bairro: {
        nome: `Bairro ${i + 2}`,
        cidade: {
          nome: `Cidade ${i + 2}`,
          estadoId: 'CF',
        },
      },
    },
    tipoImovel: {
      id: 'c11f31bd-51fc-4dfb-b4d0-5c5d504bc45a',
      finalidade: {nome: 'Comercial'},
      descricao: 'Escritório',
    },
    caracteristica: {
      areaConstruida: 100 + i * 10,
      quantidadeQuartos: 2 + (i % 2), // alterna entre 2 e 3
      quantidadeSuites: 1,
      vagasGaragem: 1,
      vagasGaragemDescobertas: 1,
    },
  })),
];

export default function FeaturedProperties({data, title}: any) {
  const match = useMediaQuery('(max-width: 488px)');
  const [controlledSwiper, setControlledSwiper] = useState(null);

  const renderProperties = data?.map((item, index) => (
    <SwiperSlide key={index}>
      <CardImmobile immobile={{...item}} />
    </SwiperSlide>
  ));
  return (
    <section>
      <div className="container">
        <header className="section-header text-center mb-4 pb-3">
          <p>{title}</p>
        </header>

        <div
          className="items"
          style={{
            height: 'auto',
          }}>
          <Swiper
            loop={true}
            navigation
            slidesPerView={match ? 1 : 2}
            spaceBetween={30}
            modules={[Autoplay, Navigation]}
            controller={{
              control: controlledSwiper,
            }}
            autoplay={{
              delay: 3000,
            }}
            style={{
              paddingBlock: 30,
            }}>
            {renderProperties}
          </Swiper>
        </div>
      </div>
    </section>
  );
}
