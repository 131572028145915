import React from 'react';

import Icon from '@components/Comparador/IconComparator';

const Whats = ({
  phoneNumber = 'Sem número'
}): JSX.Element => {
  return (
    <a
      className="whatsapp text-center"
      href={`https://wa.me/${phoneNumber}`}
      target="_blank"
      style={{
        borderRadius: 99,
      }}>
      <Icon icon="whatsapp" size={32} color="#fff" />
    </a>
  );
};

export default Whats;
