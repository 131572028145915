import React, {useEffect, useState} from 'react';
import {debounce} from 'lodash';

import {
  CategorySelect,
  Container,
  SearchButton,
  SearchInputContainer,
  Tab,
  TabButton,
  TabContainer,
  Tabs,
} from './styles';
import DebounceSelect from './DebouncedSelected';

import type {NewSearchBarProps} from './types';

import fetchLocationByTerm from '@services/fetch-location-by.term';
import fetchPurposes from '@services/fetch-purposes';

const optionTypes = [
  {
    value: 'Comprar',
    label: 'Comprar',
    color: '#FFA500',
  },
  ,
  {
    value: 'Alugar',
    label: 'Alugar',
    color: '#FFA500',
  },
];

export default function NewSearchBar({onSubmit, isLoading}: NewSearchBarProps) {
  const [purposes, setPurposes] = useState([]);
  const [searchCopy, setSearchCopy] = useState([]);
  const [locationValues, setLocationValues] = useState([]);
  const [childToParentMap, setChildToParentMap] = useState({});

  const [params, setParams] = useState({
    operacaoImobiliaria: 'Comprar',
    finalidade: null,
    tipoImovel: null,
    locais: {
      cidades: [],
      estados: [],
      bairros: [],
      logradouros: [],
      filtro: '',
    },
  });

  const buildChildToParentMap = (nodes, parentTitle) => {
    nodes.forEach((node) => {
      if (node.children) {
        buildChildToParentMap(node.children, node.title);
      }
      if (parentTitle) {
        childToParentMap[node.key] = parentTitle;
      }
    });
  };

  const onChangeField = (key: string, value: any) => {
    if (key === 'finalidade') {
      const selected = findParentTitle(purposes, value);
      let parent = selected.parent;
      let paramsToSet = {
        finalidade: null,
        tipoImovel: null,
      };

      paramsToSet.tipoImovel = null;
      paramsToSet.finalidade = parent.split('_')[0];

      if (parent.split('_').length > 1) {
        paramsToSet.tipoImovel = parent.split('_')[1];
      }

      if (selected.hasOwnProperty('child')) {
        parent = selected.child.split('_')[0];
        let child = selected.child.split('_')[1];
        paramsToSet.tipoImovel = child;
        paramsToSet.finalidade = parent;
      }

      setParams((current) => ({
        ...current,
        ...paramsToSet,
      }));
    } else setParams((current) => ({...current, [key]: value}));
  };

  const getSearchByTerm = async (term) => {
    const request = await fetchLocationByTerm(term);
    let parsedResults = [];

    request.map((e) => {
      let options;
      options = e.options.map((o) => {
        return {
          id: o.id,
          value: o.label,
          label: o.label,
          type: e.label ?? '',
        };
      });
      parsedResults = [...parsedResults, ...options];
    });

    return parsedResults;
  };

  const handleSubmit = () => {
    const query = objectToQueryParams(params);
    if (onSubmit) {
      onSubmit(query);
    }
  };

  const debouncedHandleUpdateSearch = debounce((value) => {
    const updatedSearch = value.map((item) => {
      const foundItem = searchCopy.find(
        (searchItem) => searchItem.value == item.value,
      );
      return foundItem ? foundItem : item;
    });

    setLocationValues(updatedSearch);
  }, 300);

  const handleSetNewOptions = (newOptions) => {
    const newSearchCopy = newOptions.filter(
      (newOption) => !searchCopy.find((item) => item.value == newOption.value),
    );

    setSearchCopy((current) => [...current, ...newSearchCopy]);
  };

  useEffect(() => {
    const getPurposes = async () => {
      const request = await fetchPurposes();
      if (request && request.length > 0) {
        const result = request.map((parent) => {
          return {
            ...parent,
            value: parent.label,
            key: parent.label,
            children: parent.children.map((child) => {
              const combinedKey = `${parent.label}_${child.label}`;
              return {
                ...child,
                value: combinedKey,
                key: combinedKey,
              };
            }),
          };
        });

        setPurposes(result);
      }
    };

    getPurposes();
  }, []);

  function getFormattedLocationValues(newLocationValues) {
    const locationTypes = ['Cidades', 'Estados', 'Bairros', 'Logradouros'];
    const locations = {
      cidades: [],
      estados: [],
      bairros: [],
      logradouros: [],
      filtro: '',
    };

    for (let index in newLocationValues) {
      const location = newLocationValues[index];
      const locationType = locationTypes.indexOf(location.type);
      const sanitizedValue = String(location?.value)
        .toLowerCase()
        .replace(',', ' -')
        .trim();

      let isCustom = false;

      if (locationType === -1) {
        isCustom = true;

        if (locations.filtro.includes(sanitizedValue)) {
          continue;
        } else {
          if (locations.filtro === '' || locations.filtro.length === 0) {
            locations.filtro = sanitizedValue;
          } else {
            locations.filtro += `+${sanitizedValue}`;
          }
        }
      }

      if (isCustom) continue;
      const type = locationTypes[locationType].toLowerCase();

      if (type) {
        locations[type].push(sanitizedValue);
      }
    }

    return locations;
  }

  function concatenateArrayValues(array) {
    if (!array || array.length === 0) return null;
    return array.join(',');
  }

  function objectToQueryParams(obj) {
    const queryParams = new URLSearchParams();

    Object.keys(obj).forEach((key) => {
      if (key === 'locais') {
        const cidades = concatenateArrayValues(obj[key].cidades);
        const estados = concatenateArrayValues(obj[key].estados);
        const bairros = concatenateArrayValues(obj[key].bairros);
        const logradouros = concatenateArrayValues(obj[key].logradouros);

        if (cidades) queryParams.append('cidades', cidades);
        if (estados) queryParams.append('estados', estados);
        if (bairros) queryParams.append('bairros', bairros);
        if (logradouros) queryParams.append('logradouros', logradouros);

        if (obj[key].filtro && obj[key].filtro !== '') {
          queryParams.append('filtro', obj[key].filtro);
        }

        return;
      }

      if (obj[key]) queryParams.append(key, obj[key]);
    });

    return queryParams.toString();
  }

  const mapLocationsToParams = (value) => {
    const updatedLocations = value.map((item) => {
      const foundItem = searchCopy.find(
        (searchItem) => searchItem.value == item.value,
      );

      return foundItem ? foundItem : item;
    });

    const newLocations: any = getFormattedLocationValues(updatedLocations);
    let locationParams: any = {
      locais: newLocations,
    };

    if (newLocations.locais?.filtro && newLocations.locais?.filtro !== '') {
      locationParams = {
        ...locationParams,
        filtro: newLocations.filtro,
      };
    }

    setParams((current) => ({...current, ...locationParams}));
  };

  const handleUpdateSearch = (value) => {
    mapLocationsToParams(value);
    debouncedHandleUpdateSearch(value);
  };

  const renderOptionsType = optionTypes.map((optionType) => {
    return (
      <Tab key={`${optionType.label}${Math.random()}`}>
        <TabButton
          active={params.operacaoImobiliaria == optionType.value}
          onClick={() =>
            onChangeField('operacaoImobiliaria', optionType.value)
          }>
          <span>{optionType.label}</span>
          <span className="line"></span>
        </TabButton>
      </Tab>
    );
  });

  useEffect(() => {
    buildChildToParentMap(purposes, null);
    setChildToParentMap(childToParentMap);
  }, []);

  const findParentTitle = (nodes, childValue) => {
    for (const node of nodes) {
      if (node.children) {
        if (node.children.some((child) => child.value === childValue)) {
          return {
            child: childValue,
            parent: node?.label,
          };
        } else {
          const found = findParentTitle(node.children, childValue);
          if (found) return found;
        }
      }
    }
    return {parent: childValue};
  };

  return (
    <Container>
      <TabContainer>
        <Tabs>{renderOptionsType}</Tabs>
      </TabContainer>
      <SearchInputContainer>
        <CategorySelect
          showSearch
          size="large"
          placeholder="Tipo Imóvel"
          treeDefaultExpandAll={true}
          onChange={(e) => onChangeField('finalidade', e)}
          treeData={purposes}
          bordered
        />
        <DebounceSelect
          mode="tags"
          size="large"
          showArrow
          value={locationValues}
          placeholder="Digite código, cidade, bairro ou palavra-chave"
          onChange={handleUpdateSearch}
          maxTagCount="responsive"
          getLastOptions={handleSetNewOptions}
          fetchOptions={getSearchByTerm}
        />
        <SearchButton type="primary" onClick={handleSubmit} loading={isLoading}>
          Buscar
        </SearchButton>
      </SearchInputContainer>
    </Container>
  );
}
