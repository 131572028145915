import React, {Fragment} from 'react';

import type {AppProps} from 'next/app';

import Navbar from '@components/Navbar';
import NewsLetter from '@components/NewsLetter';
import Contact from '@components/Contact';
import Footer from '@components/Footer';

export type LayoutProps = AppProps & {
  children: React.ReactNode;
};

const Layout = ({children}: LayoutProps): JSX.Element => {
  return (
    <Fragment>
      <Navbar />
      <main id="main">{children}</main>
      <NewsLetter />
      <Contact />
      <Footer />
    </Fragment>
  );
};

export default Layout;
